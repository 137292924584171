export default {
    "inquiries": {
        "issue_category": "Сұрақ санаты",
        "sent_to": "Жіберілді",
        "inquiry_text": "Өтініш мәтіні",
        "no_data": "Жоқ",
        "history_changes": "Өзгерістер тарихы",
        "initial_risk_assessment_by": "Алғашқы тәуекелді бағалауды жасаған",
        "assessment_criteria": "Бағалау критерийлері",
        "yes": "Иә",
        "no": "Жоқ",
        "head_of_apparatus": "Аппарат басшысы",
        "main_leader_or_deputies": "Басшы/Орынбасарлар",
        "main_leader": "Басшы",
        "deputies": "Орынбасарлар",
        "confirm_delete_title": "Сіз шынымен тәуекел бағалауды жоюды қалайсыз ба?",
        "delete": "Жою",
        "cancel": "Жабу",
        "delete_success": "Тәуекел бағалау жойылды",
        "sorting": "Сұрыптау",
        "new": "Жаңа",
        "processed": "Өңделген",
        "current_month": "Ағымдағы ай",
        "current_year": "Ағымдағы жыл",
        "current_day": "Текущий день",
        "period": "Кезең",
        "start": "бастау",
        "end": "аяқтау",
        "total": "Барлығы",
        "points_0": "0 ұпай",
        "points_1_2": "1-2 ұпай",
        "points_3_5": "3-5 ұпай",
        "points_6_10": "6-10 ұпай",
        "organization": "Ұйым",
        "with_departments": "Құрылымдық бөлімшелермен",
        "departments_only": "Құрылымдық бөлімшелер",
        summary: "Жүгіну сипаты",
        noData: "Деректер жоқ",
        sentTo: "Жіберілді",
        inquiryText: "Жүгіну мәтіні",
        evaluationCriteria: "Бағалау критерийлері",
        sortByNumberAsc: "Нөмір бойынша А..я",
        sortByNumberDesc: "Нөмір бойынша Я..а",
        sortNewestFirst: "Алдымен жаңалары",
        sortOldestFirst: "Алдымен ескілері",
        "with_subdivisions": "Құрылымдық бөлімшелермен бірге",
        "subdivisions": "Құрылымдық бөлімшелер",
        "period_start": "Кезеңнің басталуы",
        "period_end": "Кезеңнің аяқталуы",
        "responsible": "Жауапты",
        "issueDate": "Өтініштің түскен күні:",
        "datePlaceholder": "Күнді таңдаңыз",
        "orgSelect": "Ұйымды таңдаңыз",
        "resNumber": "Өтініш нөмірі:",
        "resNumberPlaceholder": "Өтініш нөмірін енгізіңіз",
        "resType": "Өтініш түрі:",
        "resTypePlaceholder": "Өтініш түрін таңдаңыз",
        "issueCategoryPlaceholder": "Сұрақ категориясын көрсетіңіз",
        "resSt": "Қарауға жіберілді:",
        "resText": "Өтініш мәтіні (қажет болса):",
        "resCr": "Өтініштің тәуекел критерийлерін белгілеңіз:",
        "resTotal": "Жалпы мән:",
        "touch": "Басу",
        "cancel": "Бас тарту",
        "save": "Сақтау",
        "create": "Құру",
        "field_required": "Толтыру міндетті",
        "resUpdated": "Өтініш жаңартылды",
        "resCreated": "Өтініш құрылды",
        "resError": "Қате",
        "dataError": "Деректер дұрыс емес",
        "resEdit": "Өтінішті редакциялау",
        "resAdd": "Өтініш қосу",
        "provide_address": "Мекенжайды көрсетіңіз",
        "map_select": "Картадан таңдаңыз",
        "spec": "Специалист",
        "inc_date": "Дата обращения",
        "inc_view": "Просмотр обращения",
        "information": "Информация",
        "treatmentCategory": "Категория обращения",
        "statusFilter": "Фильтровать по статусу",
        "orgFilter": "Фильтровать по иерархии",
        "sortList": "Сортировать список",
        "dateFilter": "Фильтровать по сроку",
        "filters": "Фильтры",
        "total_request": "Всего обращений"
    }
}